import { template as template_e59a1a3613064714b02fac68b7846cf0 } from "@ember/template-compiler";
const FKText = template_e59a1a3613064714b02fac68b7846cf0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
