define("discourse/plugins/discourse-poster-location/discourse/templates/connectors/user-custom-preferences/user-posterlocation-preferences", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  {{#if siteSettings.posterlocation_enabled}}
    <div class="control-group">
      <label class="control-label">Yr Location (shows up on posts)</label>
      <div class="controls">
        {{ input value=model.custom_fields.posterlocation }}
      </div>
    </div>
  {{/if}}
  
  */
  {
    "id": "eevNfEHK",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"posterlocation_enabled\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"Yr Location (shows up on posts)\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"value\"],[[30,0,[\"model\",\"custom_fields\",\"posterlocation\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-poster-location/discourse/templates/connectors/user-custom-preferences/user-posterlocation-preferences.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-poster-location/discourse/templates/connectors/user-custom-preferences/user-posterlocation-preferences.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"div\",\"label\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-poster-location/discourse/templates/connectors/user-custom-preferences/user-posterlocation-preferences.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});