import { template as template_d4b8cb261a5b44be8c8cd597596b3f70 } from "@ember/template-compiler";
const FKLabel = template_d4b8cb261a5b44be8c8cd597596b3f70(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
