import { template as template_ed0e12729ebb403ba95af2e5d60c62d1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ed0e12729ebb403ba95af2e5d60c62d1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
