import { template as template_6d3d479f22ad4a3193e54163571d76ea } from "@ember/template-compiler";
const SidebarSectionMessage = template_6d3d479f22ad4a3193e54163571d76ea(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
